export const updateImageSize = (
  url: string,
  newWidth?: number,
  onlyReplaceExistingSize = false,
): string => {
  const domains = ['images.fie.futurecdn.net', 'cdn.mos.cms.futurecdn.net'];

  try {
    if (newWidth) {
      const parsedUrl = new URL(url);

      if (!domains.includes(parsedUrl.hostname)) {
        return url;
      }

      // find the pattern `-number-number` before the extension
      const pattern = /-(\d+)-(\d+)(?=\.[^.]+(?:\.webp)?$)/;

      if (pattern.test(url)) {
        return url.replace(pattern, `-${newWidth}-$2`);
      }

      if (!onlyReplaceExistingSize) {
        // add the pattern `-number-80` before the extension
        return url.replace(/(?=\.[^.]+(?:\.webp)?$)/, `-${newWidth}-80`);
      }
    }

    return url;
  } catch (error) {
    return url;
  }
};
